<template>
  <div class="appeals-faq-list">
    <button
      class="appeals-faq-item"
      :class="{ 'appeals-faq-item--active': activeFaq === i }"
      v-for="(faq, i) in faqs"
      :key="i"
      @click="toggleFaq(i)"
      type="button"
    >
      <span class="appeals-faq-item__title">
        <span>{{ faq.title }}</span>
        <IconComponent category="vuesax-bold" v-if="activeFaq === i" name="minus-circle" />
        <IconComponent category="vuesax-bold" v-else name="add-circle" />
      </span>
      <span class="appeals-faq-item__content" v-if="activeFaq === i">
        <EditorJSComponent :text="faq | getArticle" v-if="faq.description" />
      </span>
    </button>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import FAQS from "gql/pages/AppealFaqPage.graphql";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "AppealsFaq",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: FAQS,
      })
      .then(({ data }) => {
        store.dispatch("save", data);
      })
      .catch(() => {});
  },
  data() {
    return {
      activeFaq: null,
    };
  },
  computed: {
    faqs() {
      return this.$store.state.faqs;
    },
  },
  methods: {
    getDescription(faq) {
      try {
        return JSON.parse(faq.description);
      } catch {
        return {
          blocks: [
            {
              type: "paragraph",
              data: { text: faq.description },
            },
          ],
        };
      }
    },
    toggleFaq(i) {
      if (this.activeFaq === i) {
        this.activeFaq = null;
        return;
      }
      this.activeFaq = i;
    },
  },
  metaInfo: {
    title: "Часто задаваемые вопросы",
  },
  components: { EditorJSComponent, IconComponent },
};
</script>

<style lang="stylus">
.appeals-faq-list {
  display grid
  grid-gap 10px
  width 100%
}

.appeals-faq-item {
  width 100%
  background: var(--white);
  border: 1px solid var(--gray-dark);
  border-radius: 10px;
  display grid
  grid-gap 10px
  padding 15px 20px
  cursor pointer
  text-align left

  &__title {
    display flex
    justify-content space-between
    align-items center
    gap 30px
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
    +below(1024px) {
      font-size 1em;
      line-height 26px
    }

    ^[0]--active & {
      color var(--main)
    }

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--dark)

        ^[0]--active & {
          fill var(--main)
        }
      }
    }
  }

  &__content {
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: var(--dark);
    +below(1024px) {
      font-size: 0.875em;
      line-height: 22px;
    }
  }
}
</style>
